
          @use 'sass:math';
          @use 'sass:color';
          @import "src/core/assets/scss/variables.scss";
          @import "src/core/assets/scss/mixins.scss";
        
$videoRedColor: #e41a4a;

.root {
  background-color: $videoBgColor;
  // background: $videoBgGradient;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  overflow: hidden;
  gap: 2%;
}

.curtain {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition:
    all $transitionTimeMs,
    opacity $effectTimeMs,
    background $effectTimeMs;
  background-color: $videoBgColor;
  // background: $videoBgGradient;
  opacity: 0;
  pointer-events: none;
  .started & {
    opacity: 1;
    pointer-events: all;
  }
}

$textShadowSize: 0.3vw;

.title {
  // font-size: 6vw;
  text-align: center;
  // font-weight: 600;
  color: #fff;
  text-shadow: $textShadowSize $textShadowSize color.change(black, $alpha: 0.4);
  padding: 5% 10%;
}

.warningText {
  font-size: 3vw;
  text-shadow: $textShadowSize $textShadowSize color.change(black, $alpha: 0.4);
  color: white;
}

.button {
  &:global(.MuiButtonBase-root) {
    overflow: hidden;
    &:before {
      background: linear-gradient(165deg, transparent 0%, color.change(black, $alpha: 0.5) 100%);
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      opacity: 0.5;
      transition: all $transitionTimeMs;
    }
    transition: all $transitionTimeMs;
    $bgColor: $videoBlueColor;
    background-color: $bgColor;
    // background-color: $videoRedColor;
    // background: linear-gradient(165deg, $videoRedColor 0%, color.mix($videoRedColor, black, 30%) 100%);
    border: 0.5vw solid color.change(white, $alpha: 0.8);
    font-size: 4vw;
    color: white; // color.mix(white, $videoRedColor, 100%);
    text-shadow: $textShadowSize $textShadowSize color.change(black, $alpha: 0.4);
    padding: 2vw 5vw;
    border-radius: 10vw;
    box-shadow:
      1vw 1vw 0 1vw color.change(black, $alpha: 0.3),
      inset 0.2vw 0.2vw 0 0.4vw color.change(black, $alpha: 0.2);
    opacity: 0.8;
    $hoverOffsetTop: -0.3vw;
    $hoverOffsetLeft: -0.2vw;
    &:hover {
      opacity: 1;
      margin-top: $hoverOffsetTop;
      margin-left: $hoverOffsetLeft;
      margin-bottom: -$hoverOffsetTop;
      margin-right: -$hoverOffsetLeft;
      box-shadow:
        1.2vw 1.5vw 0 1vw color.change(black, $alpha: 0.1),
        inset 0.2vw 0.2vw 0 0.5vw color.change(black, $alpha: 0.1);
      &:before {
        opacity: 0.3;
      }
      // background: linear-gradient(165deg, $videoRedColor 0%, color.mix($videoRedColor, black, 40%) 100%);
    }
    &:active {
      margin-top: -$hoverOffsetTop;
      margin-left: -$hoverOffsetLeft;
      margin-bottom: $hoverOffsetTop;
      margin-right: $hoverOffsetLeft;
      box-shadow:
        0.5vw 0.5vw 0 0 color.change(black, $alpha: 0.5),
        inset 0.2vw 0.2vw 0 0.5vw color.change(black, $alpha: 0.5);
      &:before {
        opacity: 0.8;
      }
      // background: linear-gradient(165deg, $videoRedColor 0%, color.mix($videoRedColor, black, 40%) 100%);
    }
  }
}
