
          @use 'sass:math';
          @use 'sass:color';
          @import "src/core/assets/scss/variables.scss";
          @import "src/core/assets/scss/mixins.scss";
        
.root {
  @include fullSizeBlock;
  width: 100%;
  display: flex;
}

.selectorBox,
.demoBox {
  width: 100%;
}

.selectorBox {
  padding: 24px;
}

.demoBox {
  flex: 1;
  @include fullSizeBlock;
}
.scrollable {
  // Set full-size
  flex: 1;
  // Full-width
  width: 100%;
  // Flex container (to contain inner boxes)
  display: flex;
  flex-direction: column;
  // justify-content: center; // NOTE: Not compatible with overflow:auto
  align-items: stretch;
  // Enable scroll
  overflow: auto;
  // Paddings (if doesn't contain nested scrolls?)
  padding: 12px 24px;
}
