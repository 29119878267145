
          @use 'sass:math';
          @use 'sass:color';
          @import "src/core/assets/scss/variables.scss";
          @import "src/core/assets/scss/mixins.scss";
        
div.paperContainer {
  // TODO: Adjust paper properties?
  font-size: $defaultFontSizePx;
}
.fullSize {
  @include singleFullSizeContainer;
}
