
          @use 'sass:math';
          @use 'sass:color';
          @import "src/core/assets/scss/variables.scss";
          @import "src/core/assets/scss/mixins.scss";
        
.root,
.container {
  position: relative;
  overflow: hidden;
}
.scrollable {
  &.root {
    overflow: auto;
  }
  .container {
    overflow: visible;
  }
}
.fullCenter {
  width: 100%;
  .container {
    width: 100%;
    margin: auto;
    display: flex;
  }
}
.clippable {
  /*!KEEP*/
}
